import axios from 'axios'
import { auth } from 'services/firebase.controller'
import { API_HOST } from 'utils/constants'

export const ENDPOINTS = {
  workflow: {
    instances: `/workflow/instances/`,
    transition: `/workflow/instances/transition`,
    timeConfig: '/workflow/workflows/stateTimeConfig',
    versions: `/workflow/workflows/versions`,
  },
  file: {
    commentCreate: `/filecomment.create`,
    commentFind: `/filecomment.find`,
    download: `/file.download`,
    fileRequest: `/filerequest.find`,
    contractShip: `/contract.ship`,
    list: `/file.list`,
    requestCreate: `/filerequest.create`,
    requestFind: `/filerequest.find`,
    update: `/file.update`,
    uploadFile: `/file.upload`,
  },
  interaction: {
    list: `/interaction.list`,
    create: `/interaction.create`,
    info: `/interaction.info`,
    update: `/interaction.update`,
  },
  customerComment: {
    create: '/customercomment.create',
    find: '/customercomment.find',
  },
  relation: {
    create: '/relation.create',
    find: '/relation.find',
    update: '/relation.update',
    info: '/relation.info',
  },
  category: {
    find: '/category.find',
  },
  contract: {
    find: '/contract.find',
  },
  loan: {
    find: `/loan.find`,
    concepts: '/loan.concepts',
    projection: '/loan.projection',
  },
  payment: {
    find: '/payment.find',
    annulment: '/payment.annulment',
    comments: '/paymentComments.find',
  },
  bulk: {
    upload: `/bulk.upload`,
    find: `/bulk.find`,
  },
  collection: {
    create: `/collection.create`,
    find: `/collection.find`,
  },
  customer: {
    getInfo: `/customer.info`,
    getFiles: `/file.find`,
    list: `/customer.list`,
    update: '/customer.update',
    referrer: `/referrer.create`,
    history: '/customer.history',
    onboardingHistory: '/onboarding.history',
    statement: '/customer.statement',
    getIndustries: `/industry.list`,
    contractFind: '/contract.find',
  },
  dashboard: {
    onboarding: `/onboarding.list`,
    buyers: `/onboarding.statement`,
  },
  finance: {
    find: '/finance.find',
    update: '/finance.update',
  },
  shareholder: {
    getInfo: `/shareholder.info`,
    update: `/shareholder.update`,
  },
  assign: {
    update: '/assignation.update',
    find: '/assignation.find',
  },
  profile: {
    find: '/iam/profile.find',
  },
  provider: {
    create: '/provider.create',
    find: '/provider.find',
  },
  user: {
    find: `iam/user.find`,
    getInfo: `/user.info`,
    update: `/user.update`,
  },
  location: {
    create: `/location.create`,
    getInfo: `/location.info`,
    update: `/location.update`,
  },
  line: {
    create: '/line.create',
    createCondition: '/line_condition.create',
    find: '/line.find',
    findConditions: '/line_condition.info',
    info: `/line.info`,
    assign: '/line.assign',
    evaluate: '/line.evaluate',
    lineUpdate: `/line.update`,
    lineConditionUpdate: `/line_condition.update`,
    findLineConditionsChange: `/lineConditionsChange.find`,
    statement: '/line.statement',
    evaluationFind: '/evaluation.find',
  },
  person: {
    create: `/person.create`,
    getInfo: `/person.info`,
    update: `/person.update`,
  },
  agent: {
    create: `/agent.create`,
    getInfo: `/agent.info`,
    update: `/agent.update`,
  },
  business: {
    create: `/business.create`,
    getInfo: `/business.info`,
    update: `/business.update`,
  },
  portfolio: {
    freeze: '/loan.freeze',
    unfreeze: '/loan.unfreeze',
    cancel: '/loan.cancel',
    restructure: '/loan.restructure',
    comments: '/loanActions.commentFind',
  },
  proposal: {
    getByCustomer: '/proposal.getByCustomer',
    findEvaluationsByCustomer: '/proposalEvaluations.findByCustomer',
  },
}

export const setUpAxios = () =>
  axios.interceptors.request.use(
    async (config) => {
      const currentUser = auth?.currentUser
      //config.headers.customHeader = `{'roles':'xd'}`
      if (currentUser) {
        config.headers.authorization = `Bearer ${await currentUser.getIdToken()}`
        config.headers['x-api-key'] = process.env.REACT_APP_GATEWAY_API_KEY
      } else {
        config.cancelToken = new axios.CancelToken((cancel) =>
          cancel('No user signed in')
        )
      }
      // Do something before request is sent
      config.baseURL = `${API_HOST}/api`

      return config
    },
    (error) => {
      console.log('error', error)
      // Do something with request error
      return Promise.reject(error)
    }
  )
